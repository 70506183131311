import BaseContractService from "./baseContractService";
import abi from '../abi/wearables.json'

class WearablesContractService extends BaseContractService {
  constructor() {
    super(process.env.VUE_APP_WEARABLES_CONTRACT_ADDRESS, abi)
  }

  isApprovedForAll(address) {
    return this.contract.methods.isApprovedForAll(address, process.env.VUE_APP_REDEMPTION_CONTRACT_ADDRESS).call()
  }

  setApprovalForAll(address) {
    return this.contract.methods.setApprovalForAll(process.env.VUE_APP_REDEMPTION_CONTRACT_ADDRESS, true).send({
      from: address
    })
  }
}

export default new WearablesContractService()
