export function randomStr(len = 20, charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789') {
  const arr = charset.split('')
  let result = '';

  for (let i = len; i > 0; i--) {
    result += arr[Math.floor(Math.random() * arr.length)];
  }

  return result;
}
