import BaseContractService from "./baseContractService";
import abi from '../abi/redemption.json'

class RedemptionContractService extends BaseContractService {
  constructor() {
    super(process.env.VUE_APP_REDEMPTION_CONTRACT_ADDRESS, abi)
  }

  balanceOf(address) {
    return this.contract.methods.balanceOf(address, 1).call()
  }

  burn(address, quantity) {
    return this.contract.methods.burn(1, quantity).send({
      from: address
    })
  }
}

export default new RedemptionContractService()
