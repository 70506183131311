<template>
  <div class="home-screen">
    <div class="container">
      <div class="row align-items-center mt-5">
        <div class="col-md-6">
          <h1>BOMBER JACKET BONFIRE</h1>
          <h2>Claim Physical Bomber Jacket</h2>
          <p>Your chance to recieve a physical DeadHeads Bomber Jacket! Throw your NFT into the bonfire and have a jacket shipped to you!</p>
          <a class="mb-3 d-inline-block text-nowrap" href="https://opensea.io/assets/0x18e4f33b727e4658832576379d4549e31ab7c4cb/1" target="_blank">
            OpenSea: Bomber Jacket Market
          </a>
        </div>
        <div class="col-md-6">
          <img src="/static/img/bomber-jacket.gif" alt="Bomber Jacket Gif" width="100%">
        </div>
      </div>

      <div class="divider my-5"></div>

      <h1>BURN YOUR BOMBER JACKET NFT FOR A PHYSICAL BOMBER JACKET</h1>

      <h2>Instructions</h2>

      <p>1. Connect wallet</p>
      <p>2. Approve contract to burn Bomber Jacket NFT</p>
      <p>3. Sign a free transaction</p>
      <p>4. Submit transaction to burn Bomber Jacket NFT</p>
      <p>5. Submit shipping details form to recieve physical Bomber Jacket</p>

      <h2 class="text-center text-md-left mt-5 mb-4">Burn NFT And Claim Bomber Jacket</h2>

      <div class="text-center text-md-left mb-5">
        <button class="claim-button" @click="openBurnModal">
          <template v-if="isConnected && isApprovedForAll">
            REDEEM
          </template>
          <template v-else-if="isConnected">
            APPROVE
          </template>
          <template v-else>
            CONNECT WALLET
          </template>
        </button>
      </div>

      <h1 class="text-center">
        SOCIALS
      </h1>

      <div class="d-flex flex-column align-items-center flex-md-row justify-content-md-between mb-5">
        <a href="https://www.youtube.com/watch?v=yVygJiV61vM" target="_blank">
          YouTube
        </a>
        <a href="https://discord.gg/deadheads" target="_blank">
          Discord
        </a>
        <a href="https://twitter.com/DeadHeadsNFT" target="_blank">
          Twitter
        </a>
        <a href="https://instagram.com/deadheads_nft" target="_blank">
          Instagram
        </a>
      </div>
    </div>

    <v-burn-modal
      :active="modals.burn.active"
      :totalTokens="totalTokens"
      @close="closeBurnModal"
      @burned="onBurned"
    ></v-burn-modal>

    <v-shipment-details-modal
      :active="modals.shipmentDetails.active"
      :totalPending="modals.shipmentDetails.totalPending"
      :signature="signature"
      @close="closeShipmentDetailsModal"
    ></v-shipment-details-modal>

    <v-loading-modal
      :active="modals.loading.active"
    ></v-loading-modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import VBurnModal from '../components/BurnModal.vue'
import VShipmentDetailsModal from '../components/ShipmentDetailsModal.vue'
import VLoadingModal from '../components/LoadingModal.vue'
import web3 from '../services/web3Service.js'
import wearablesContractService from '../services/wearablesContractService.js'
import redemptionContractService from '../services/redemptionContractService.js'
import { getOrders } from '../services/wearablesApiService.js'

export default {
  name: 'Home',
  components: {
    VBurnModal,
    VShipmentDetailsModal,
    VLoadingModal,
  },
  data() {
    return {
      modals: {
        burn: {
          active: false
        },
        shipmentDetails: {
          active: false,
          totalPending: 0
        },
        loading: {
          active: false,
        }
      },
      totalTokens: 0,
      isApprovedForAll: false,
      signature: null
    }
  },
  computed: {
    ...mapState([
      'accountAddress',
      'networkId'
    ]),
    ...mapGetters([
      'isConnected'
    ])
  },
  watch: {
    async accountAddress(address) {
      if (address && this.networkId == process.env.VUE_APP_NETWORK_ID) {
        this.totalTokens = +(await redemptionContractService.balanceOf(address))
        this.isApprovedForAll = await wearablesContractService.isApprovedForAll(address)
      } else {
        this.isApprovedForAll = false
      }

      this.modals.burn.active = false
      this.modals.shipmentDetails.active = false
      this.signature = null
    },
    networkId(id) {
      this.modals.burn.active = false
      this.modals.shipmentDetails.active = false
    }
  },
  methods: {
    async openBurnModal() {
      if (!this.isConnected) {
        try {
          await this.$wallet.connect()
        } catch (err) {
          return
        }
      }

      if (this.networkId != process.env.VUE_APP_NETWORK_ID) {
        return
      }

      this.isApprovedForAll = await wearablesContractService.isApprovedForAll(this.accountAddress)

      if (!this.isApprovedForAll) {
        try {
          this.openLoadingModal()
          await wearablesContractService.setApprovalForAll(this.accountAddress)
          this.isApprovedForAll = true
        } catch (err) {
          this.closeLoadingModal()
          return
        }
      }

      if (!this.signature) {
        try {
          this.openLoadingModal()
          this.signature = await web3.eth.personal.sign('Hi there! Sign this message to prove you have access to this wallet. This won’t cost you any Ether.', this.accountAddress)
        } catch (err) {
          this.closeLoadingModal()
          return
        }
      }

      try {
        this.openLoadingModal()
        const response = await getOrders(this.signature, this.accountAddress)
        const pendingShipmentDetails = response.data.filter(n => n.status == 'PENDING_SHIPMENT_DETAILS').length

        if (pendingShipmentDetails > 0) {
          this.modals.shipmentDetails.totalPending = pendingShipmentDetails
          this.openShipmentDetailsModal()
        } else if (this.totalTokens > 0) {
          this.modals.burn.active = true
        } else {
          this.$showSnackbar(`You do not have any Bomber Jacket NFT.`, 'danger')
        }
      } catch (err) {
        console.error(err)
      } finally {
        this.closeLoadingModal()
      }
    },
    openShipmentDetailsModal() {
      this.modals.shipmentDetails.active = true
    },
    openLoadingModal() {
      this.modals.loading.active = true
    },
    closeBurnModal() {
      this.modals.burn.active = false
    },
    closeShipmentDetailsModal() {
      this.modals.shipmentDetails.active = false
    },
    closeLoadingModal() {
      this.modals.loading.active = false
    },
    onBurned(quantity) {
      this.totalTokens -= quantity
      this.closeBurnModal()
      this.openBurnModal()
    },
  },
}
</script>
