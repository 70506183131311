<template>
  <div class="vue-snackbar-container">
    <v-snackbar
      v-for="snackbar in snackbars"
      :key="snackbar.data.id"
      :show="snackbar.data.show"
      :color="snackbar.data.color"
      @close="snackbar.close()"
    >
      {{ snackbar.data.content }}
    </v-snackbar>
  </div>
</template>

<script>
import VSnackbar from './Snackbar.vue'
import { mapState } from 'vuex'
export default {
  name: 'SnackbarContainer',
  components: {
    VSnackbar
  },
  computed: mapState(['snackbars']),
}
</script>

<style scoped>
  .vue-snackbar-container {
    position: fixed;
    top: 0;
    width: 100%;
    pointer-events: none;
    z-index: 1111;
  }
</style>
