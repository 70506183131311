import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    accountAddress: null,
    networkId: null,
    snackbars: []
  },
  getters: {
    isConnected(state) {
      return state.accountAddress
        && state.networkId == process.env.VUE_APP_NETWORK_ID
    }
  },
  mutations: {
    SET_ACCOUNT_ADDRESS(state, payload) {
      state.accountAddress = payload
    },
    SET_NETWORK_ID(state, payload) {
      state.networkId = payload
    },
    PUSH_SNACKBAR(state, snackbar) {
      state.snackbars.push(snackbar)
    },
    POP_SNACKBAR(state, snackbar) {
      state.snackbars = state.snackbars.filter(sb => sb.data.id != snackbar.data.id)
    }
  },
  actions: {
  },
  modules: {
  }
})
