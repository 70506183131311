import axios from 'axios'

export function getOrders(signature, address) {
  return axios.get('https://dh.collection.tools/wearables/orders', {
    headers: {
      signature,
      address
    }
  })
}

export function postOrder(signature, address, data) {
  return axios.post('https://dh.collection.tools/wearables/orders', data, {
    headers: {
      signature,
      address
    }
  })
}
