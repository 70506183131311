<template>
  <div :class="snackbarClasses" tabindex="-1">
    <span :class="iconClasses">
      <i :class="`fa fa-${icon}`"></i>
    </span>
    <div class="center-text">
      <slot />
    </div>
    <button type="button" class="close" @click="$emit('close')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "Snackbar",
  props: {
    show: {
      type: Boolean,
      required: true
    },
    color: {
      type: String,
      required: true,
    }
  },
  computed: {
    snackbarClasses() {
      let classes = 'vue-snackbar'
      classes += this.show ? ' show' : ''
      return classes
    },
    iconClasses() {
      let classes = 'icon '
      classes += this.color
      return classes
    },
    icon() {
      const icons = {
        info: 'info',
        success: 'check',
        danger: 'times',
        warning: 'exclamation',
      }
      return icons[this.color]
    }
  }
};
</script>

<style scoped>
  .vue-snackbar {
    position: relative;
    background-color: #fff;
    color: black;
    font-size: 16px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 300ms, visibility 300ms, margin-top 300ms;
    margin: 16px;
    padding: 16px;
    padding-right: 22px;
    box-shadow: 0px 3px 12px 1px rgba(0, 0, 0, .2);
    margin-top: -56px;
    border-radius: 4px;
    display: flex;
    align-items: flex-start;
    pointer-events: initial;
  }
  .vue-snackbar.show {
    opacity: 1;
    visibility: visible;
    margin-top: 16px;
  }
  .icon {
    color: white;
    font-size: 12px;
    width: 20px;
    height: 18px;
    text-align: center;
    border-radius: 100%;
    margin-top: 5px;
    margin-right: 10px;
    display: block;
  }
  .icon.info {
    background-color: #1890ff;
  }
  .icon.success {
    background-color: #52c41a;
  }
  .icon.danger {
    background-color: #f5222d;
  }
  .icon.warning {
    background-color: #faad14;
  }
  .center-text {
    width: 100%;
  }
  @media (min-width: 576px) {
    .vue-snackbar {
      width: 508px;
      margin-left: auto;
      margin-right: auto;
    }
  }
</style>
