<template>
  <v-modal :active="active" :persist="modal.persist" @close="$emit('close')">
    <div slot="body" class="text-body">
      <h5 class="text-center text-body" style="font-size: 32px; font-weight: normal;">
        <template v-if="submitting">
          Burning...
        </template>
        <template v-else>
          Burn
        </template>
      </h5>
      <div class="d-flex justify-content-center align-items-center w-100" v-if="!submitting">
        <div class="w-100">
          <p class="d-block text-center">
            How many tokens you want to burn now?
          </p>
          <div class="row justify-content-center">
            <div class="col-12">
              <input v-model="form.quantity" type="number" step="1" min="1" :max="totalTokens" class="form-control quantity-field">
            </div>
          </div>
          <div class="text-center mt-2">
            <button type="button" class="btn btn-primary btn-block" @click="handleSubmit" :disabled="submitting">
              BURN NOW
            </button>
            <div class="mt-4">
              <small>
                You have {{ totalTokens }} tokens.
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center" v-else>
        <p>
          Please confirm your transaction to burn your tokens!
        </p>
        <img src="/static/img/loader.svg" alt="Loader" width="100">
      </div>
    </div>
  </v-modal>
</template>

<script>
import { mapState } from 'vuex'
import redemptionContractService from '../services/redemptionContractService.js'
import VModal from './Modal.vue'

export default {
  name: 'BurnModal',
  components: {
    VModal,
  },
  props: {
    active: {
      type: Boolean,
      required: false
    },
    totalTokens: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      submitting: false,
      modal: {
        persist: false
      },
      form: {
        quantity: 1
      }
    }
  },
  computed: {
    ...mapState([
      'accountAddress',
      'networkId'
    ])
  },
  methods: {
    async handleSubmit() {
      this.submitting = true
      this.modal.persist = true

      try {
        await redemptionContractService.burn(this.accountAddress, this.form.quantity)
        this.$emit('burned', this.form.quantity)
        this.$showSnackbar(`You've burned ${this.form.quantity} tokens successfully.`, 'success')
      } catch (err) {
        this.$showSnackbar(err.message, 'danger')
      } finally {
        this.submitting = false
        this.modal.persist = false
      }
    }
  }
}
</script>
