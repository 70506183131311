<template>
  <v-modal :active="active" :persist="modal.persist" :large="true" @close="$emit('close')">
    <div slot="body" class="text-body">
      <h5 class="text-center text-body" style="font-size: 32px; font-weight: normal;">
        <template v-if="success">
          Congratulations!
        </template>
        <template v-else>
          Redeem
        </template>
      </h5>
      <div class="d-flex justify-content-center align-items-center w-100" v-if="!submitting && !success">
        <div class="w-100">
          <p class="d-block text-center">
            Enter Your Shipping Details
          </p>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="full_name">Full Name</label>
                <input v-model="form.shipment_details.full_name" id="full_name" type="text" :class="{'form-control': true, 'is-invalid': errors.full_name}" @input="clearInputError('full_name')">
                <span v-if="errors.full_name" class="invalid-feedback" role="alert">
                  <strong>{{ errors.full_name }}</strong>
                </span>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="country">Country</label>
                <input v-model="form.shipment_details.country" id="country" type="text" :class="{'form-control': true, 'is-invalid': errors.country}" @input="clearInputError('country')">
                <span v-if="errors.country" class="invalid-feedback" role="alert">
                  <strong>{{ errors.country }}</strong>
                </span>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="city">City</label>
                <input v-model="form.shipment_details.city" id="city" type="text" :class="{'form-control': true, 'is-invalid': errors.city}" @input="clearInputError('city')">
                <span v-if="errors.city" class="invalid-feedback" role="alert">
                  <strong>{{ errors.city }}</strong>
                </span>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="state">State</label>
                <input v-model="form.shipment_details.state" id="state" type="text" :class="{'form-control': true, 'is-invalid': errors.state}" @input="clearInputError('state')">
                <span v-if="errors.state" class="invalid-feedback" role="alert">
                  <strong>{{ errors.state }}</strong>
                </span>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="postal_code">Postal Code</label>
                <input v-model="form.shipment_details.postal_code" id="postal_code" type="text" :class="{'form-control': true, 'is-invalid': errors.postal_code}" @input="clearInputError('postal_code')">
                <span v-if="errors.postal_code" class="invalid-feedback" role="alert">
                  <strong>{{ errors.postal_code }}</strong>
                </span>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="address">Address</label>
                <input v-model="form.shipment_details.address" id="address" type="text" :class="{'form-control': true, 'is-invalid': errors.address}" @input="clearInputError('address')">
                <span v-if="errors.address" class="invalid-feedback" role="alert">
                  <strong>{{ errors.address }}</strong>
                </span>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="additional_info">Additional Info</label>
                <input v-model="form.shipment_details.additional_info" id="additional_info" type="text" :class="{'form-control': true, 'is-invalid': errors.additional_info}" @input="clearInputError('additional_info')">
                <span v-if="errors.additional_info" class="invalid-feedback" role="alert">
                  <strong>{{ errors.additional_info }}</strong>
                </span>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="phone">Phone</label>
                <input v-model="form.shipment_details.phone" id="phone" type="text" :class="{'form-control': true, 'is-invalid': errors.phone}" @input="clearInputError('phone')">
                <span v-if="errors.phone" class="invalid-feedback" role="alert">
                  <strong>{{ errors.phone }}</strong>
                </span>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="email">Email</label>
                <input v-model="form.email" id="email" type="text" :class="{'form-control': true, 'is-invalid': errors.email}" @input="clearInputError('email')">
                <span v-if="errors.email" class="invalid-feedback" role="alert">
                  <strong>{{ errors.email }}</strong>
                </span>
              </div>
            </div>
            <div class="col-sm-12">
              <h2 class="text-center mt-3">Select the sizes</h2>
              <div class="text-center">
                <button :class="{'btn-size': true, 'selected': sizeOptions[size]}" v-for="(value, size) in sizeOptions" :key="`btn-size-${size}`" :disabled="isDisabled(size)" @click="toggleSize(size)">
                  {{ size }}
                </button>
              </div>
              <div class="table-responsive" v-if="totalSelected > 0">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th class="text-center">SIZE</th>
                      <th class="text-center">QTY</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, size) in filteredSizeOptions" :key="`size-row-${size}`">
                      <td class="text-center">{{ size }}</td>
                      <td class="text-center">
                        <button class="btn-quantity" @click="subtractSize(size)">-</button>
                        <span>{{ value }}</span>
                        <button class="btn-quantity" @click="sumSize(size)">+</button>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td class="text-center">Total: {{ totalSelected }} of {{ totalPending }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p v-else class="text-center mb-5">
                <small>Please select at least one size.</small>
              </p>
            </div>
          </div>
          <div class="text-center">
            <button type="button" class="btn btn-primary btn-block" @click="handleSubmit" :disabled="submitting || totalSelected == 0">
              REDEEM NOW
            </button>
          </div>
        </div>
      </div>
      <div class="text-center" v-else-if="success">
        <p>Shipment details sent. We'll reach you on email with updates.</p>
      </div>
      <div class="text-center" v-else>
        <p>Submitting your shipping information...</p>
        <img src="/static/img/loader.svg" alt="Loader" width="100">
      </div>
    </div>
  </v-modal>
</template>

<script>
import { mapState } from 'vuex'
import { postOrder } from '../services/wearablesApiService.js'
import VModal from './Modal.vue'

export default {
  name: 'ShipmentDetailsModal',
  components: {
    VModal,
  },
  props: {
    active: {
      type: Boolean,
      required: false
    },
    totalPending: {
      type: Number,
      required: true
    },
    signature: {
      type: String|null,
      required: true
    }
  },
  data() {
    return {
      submitting: false,
      success: false,
      modal: {
        persist: false
      },
      sizeOptions: {
        S: 0,
        M: 0,
        L: 0,
        XL: 0,
        XXL: 0,
      },
      form: {
        quantity: null,
        tokenId: 1,
        shipment_details: {
          full_name: null,
          postal_code: null,
          country: null,
          city: null,
          state: null,
          address: null,
          additional_info: null,
          phone: null,
        },
        email: null,
        sizes: []
      },
      errors: {}
    }
  },
  computed: {
    ...mapState([
      'accountAddress',
    ]),
    filteredSizeOptions() {
      const sizes = {}

      for (const k in this.sizeOptions) {
        if (this.sizeOptions[k] > 0) {
          sizes[k] = this.sizeOptions[k]
        }
      }

      return sizes
    },
    totalSelected() {
      return Object.values(this.sizeOptions).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
    },
    canSubmit() {
      return this.totalSelected <= this.totalPending
    }
  },
  watch: {
    sizeOptions: {
      deep: true,
      handler(v, o) {
        const sizes = []

        for (const size in v) {
          if (+v[size] > 0) {
            sizes.push(...Array.from(Array(+v[size])).map(_ => size))
          }
        }

        this.form.sizes = sizes
        this.form.quantity = sizes.length
      }
    },
    form: {
      deep: true,
      handler(v) {
        window.localStorage.setItem('form', JSON.stringify(v))
      }
    }
  },
  methods: {
    async handleSubmit() {
      if (this.form.quantity > 0) {
        if (this.form.quantity <= this.totalPending) {
          this.submitting = true
          this.modal.persist = true

          try {
            await postOrder(this.signature, this.accountAddress, this.form)
            this.success = true
          } catch (err) {
            if (err.response) {
              const { errors } = err.response.data

              errors.forEach(e => {
                this.errors[e.param.replace('shipment_details.', '')] = e.msg
              })
            } else {
              this.$showSnackbar(err.message, 'danger')
            }
          } finally {
            this.submitting = false
            this.modal.persist = false
          }
        } else {
          this.$showSnackbar(`Limit of ${this.totalPending} reached.`, 'danger')
        }
      } else {
        this.$showSnackbar(`You must select at least one size.`, 'danger')
      }
    },
    toggleSize(size) {
      this.sizeOptions[size] =
        this.sizeOptions[size] > 0
          ? 0
          : 1
    },
    subtractSize(size) {
      this.sizeOptions[size] -= 1
    },
    sumSize(size) {
      this.sizeOptions[size] += 1

      if (this.totalSelected > this.totalPending) {
        this.subtractSize(size)
        this.$showSnackbar(`Limit of ${this.totalPending} reached. Please check your selected size quantities.`, 'danger')
      }
    },
    isSelected(size) {
      return this.sizeOptions[size] > 0
    },
    isDisabled(size) {
      return this.totalSelected >= this.totalPending
        && !this.isSelected(size)
    },
    clearInputError(name) {
      this.errors[name] = '';
    }
  },
  mounted() {
    const savedForm = JSON.parse(window.localStorage.getItem('form'))

    if (savedForm) {
      delete savedForm.sizes
      this.form = savedForm
    }
  }
}
</script>

<style scoped>
  .btn-size {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 2px solid #E5E5E5;
    background-color: white;
    color: #817979;
    font-weight: 900;
    font-size: 20px;
    margin-bottom: 24px;
  }

  .btn-size:not(:first-child) {
    margin-left: 12px;
  }

  .btn-size.selected {
    background-color: #E5E5E5;
  }

  .btn-size:disabled {
    opacity: .5;
    pointer-events: none;
  }

  .btn-quantity {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 0;
    background-color: #E5E5E5;
    color: #817979;
    font-weight: 900;
    font-size: 24px;
  }

  .btn-quantity:first-child {
    margin-right: 12px;
  }

  .btn-quantity:last-child {
    margin-left: 12px;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #f6f6f6;
  }
</style>
