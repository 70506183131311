<template>
  <div id="app">
    <router-view />
    <v-snackbar-container />
  </div>
</template>

<script>
import VSnackbarContainer from './components/SnackbarContainer.vue'

export default {
  name: 'App',
  components: {
    VSnackbarContainer
  }
}
</script>
