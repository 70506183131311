<template>
  <div :id="id" :class="{'vue-modal': true, active}" tabindex="-1" @click="handleClose">
    <div :class="{'vue-modal-content': true, 'text-left': true, 'large': large}" @click.stop>
      <div class="vue-modal-header">
        <button v-if="closeIcon && !persist" type="button" class="close float-right" @click="handleClose">
          <span aria-hidden="true">&times;</span>
        </button>

        <slot name="header" />
      </div>
      <div class="vue-modal-body">
        <slot name="body" />
      </div>
      <div class="vue-modal-footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    id: {
      type: String,
      required: false
    },
    active: {
      type: Boolean,
      required: false
    },
    closeIcon: {
      type: Boolean,
      default: true,
    },
    large: {
      type: Boolean,
      default: false,
    },
    persist: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    active(v) {
      if (v) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }
    }
  },
  methods: {
    handleClose() {
      if (!this.persist) {
        this.$emit('close');

        if (this.id) {
          this.$router.back()
        }
      }
    }
  }
}
</script>

<style scoped>
  .vue-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
    opacity: 0;
    visibility: hidden;
    transition: opacity 300ms, visibility 300ms;
    z-index: 4;
  }

  .vue-modal:target,
  .vue-modal.active {
    opacity: 1;
    visibility: visible;
  }

  .vue-modal:target .vue-modal-content,
  .vue-modal.active .vue-modal-content {
    transform: scale(1);
  }

  .vue-modal-content {
    position: relative;
    background-color: white;
    background-image: url(/static/img/background.jpg);
    -webkit-animation: background-animation 30s linear infinite;
    animation: background-animation 30s linear infinite;
    margin: 16px;
    width: 100%;
    /* border-radius: 4px; */
    transform: scale(.97);
    /* box-shadow: 0px 3px 64px 12px rgba(0, 0, 0, .1); */
    border: 4px solid rgba(0, 0, 0, .8);
    transition: transform 300ms;
  }

  .vue-modal-header,
  .vue-modal-body,
  .vue-modal-footer {
    padding: 16px;
  }

  .vue-modal-body {
    width: 100%;
    max-height: 600px;
    display: inline-block;
    overflow: auto;
  }

  @media (min-width: 576px) {
    .vue-modal-content {
      width: 508px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (min-width: 976px) {
    .vue-modal-content.large {
      width: 908px;
    }
  }

  @keyframes background-animation {
    to {
      background-position: 0 0;
    }
    0% {
      background-position: 1780px -690px;
    }
  }
</style>
